import {
    ChevronLeftOutlined,
    ChevronRightOutlined,
    Translate,
} from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { shallow } from 'zustand/shallow';

import { useTelmaPortalStore } from '@/store/telma-portal-store';

export interface BaseIconButtonProps {
    onClick: () => void;
    open?: boolean;
    icon?: string;
}

export const LanguageButton: FC<BaseIconButtonProps> = (props) => {
    return (
        <IconButton onClick={props.onClick} open={props.open} size='40px'>
            <Translate />
        </IconButton>
    );
};

export const DrawerButton: FC = () => {
    const [opened, setMenu] = useTelmaPortalStore(
        (state) => [state.opened, state.setMenu],
        shallow,
    );

    return (
        <IconButton onClick={setMenu} size='40px'>
            {opened ? <ChevronLeftOutlined /> : <ChevronRightOutlined />}
        </IconButton>
    );
};

export const RoundButton: FC<{
    children: any;
    onClick: any;
    style?: any;
    disabled?: boolean;
    onMouseDown?: any;
    sx?: any;
}> = ({ children, onClick, style, disabled, onMouseDown, sx }) => {
    const theme: any = useTheme();

    const RoundButtonSx = {
        borderRadius: `50%`,
        width: 32,
        height: 32,
        minHeight: 0,
        boxShadow: 'none',
        transition: 'all .2s ease-in-out',
        background: theme.palette.secondary.veryLight,
        color: 'white',
        '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.secondary.main,
        },
        ...sx,
    };

    return (
        <IconButton
            onClick={onClick}
            size='36px'
            style={style}
            sx={RoundButtonSx}
            disabled={disabled}
            onMouseDown={onMouseDown}
        >
            {children}
        </IconButton>
    );
};

export const RoundButtonText: FC<{
    children: any;
    onClick: any;
    style?: any;
}> = ({ children, onClick, style }) => {
    const theme: any = useTheme();

    const RoundButtonSx = {
        borderRadius: `50%`,
        width: 32,
        height: 32,
        minHeight: 0,
        boxShadow: 'none',
        transition: 'all .2s ease-in-out',
        background: 'none',
        color: theme.palette.secondary.veryLight,
        '&[aria-controls="menu-list-grow"],&:hover': {
            color: '#af8ff6',
            background: theme.palette.secondary.light,
            boxShadow: 'none',
        },
    };

    return (
        <IconButton
            onClick={onClick}
            size='36px'
            style={style}
            sx={RoundButtonSx}
        >
            {children}
        </IconButton>
    );
};

export const IconButton: FC<{
    children: any;
    onClick: any;
    open?: boolean;
    size: any;
    style?: any;
    sx?: any;
    disabled?: boolean;
    onMouseDown?: any;
}> = ({ children, onClick, open, size, style, sx, disabled, onMouseDown }) => {
    const theme: any = useTheme();

    const defaultSx = {
        borderRadius: `${theme.borderRadius}px`,
        width: size,
        height: size,
        boxShadow: 'none',
        transition: 'all .2s ease-in-out',
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light,
        },
    };

    return (
        <Fab
            sx={sx ? sx : defaultSx}
            onClick={onClick}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            style={style}
            disabled={disabled}
            onMouseDown={onMouseDown}
        >
            {children}
        </Fab>
    );
};
